
import { defineComponent } from 'vue'
import { contentMenuItems } from './tab-menu-items'
export default defineComponent({
  setup () {
    return {
      contentMenuItems
    }
  }
})
